<template>
  <a-row type="flex" justify="center">
    <a-col :lg="18" :span="23">
      <div class="treaty">
        <h1>隐私政策</h1>
        <hr>
        <div class="content">
          <div>发布日期：2021年【12】月【17】日</div>
          <div>生效日期：2022年【1】月【1】日</div>
        </div>
        <br>
        <div class="subTitle">引言</div>
        <br>
        <div>VLLSHOP产品与/或服务平台（以下简称“VLLSHOP”或“本平台”）是深圳市万鲸科技有限公司（注册地址：【深圳市南山区粤海街道高新区社区高新南九道51号航空航天大厦1号楼703】联系地址：【深圳市南山区航空航天大厦1号楼703】）（以下统称“我们”）为卖家用户（以下简称“用户”或“您”或“卖家”），通过VLLSHOP网站（域名为https://www.vllshop.com）提供建站、流量、支付和物流完整解决方案的SaaS平台。</div>
        <br>
        <div>您在使用我们的平台时，我们可能会收集和使用您（包括员工和/或代表您操作业务的人员）的相关信息以及受您委托处理您终端用户（以下合称“个人信息主体”）的个人信息。我们深知个人信息对个人信息主体的重要性，我们根据适用的法律法规，制订《VLLSHOP隐私政策》（以下简称“本政策”），并致力于保护您和/或您终端用户的个人信息安全。</div>
        <br>
        <div>我们希望通过本政策向个人信息主体说明VLLSHOP在提供产品与/或服务过程中，如何收集、使用、存储、共享、转让、公开批露个人信息的目的、方式、范围和信息安全保护措施，以及使个人信息主体实现管理其个人信息的权利和方法。请您结合<a href="/treaty/userAgreement" target="_blank">《VLLSHOP用户协议》</a>阅读本政策。以下是我们的特别提示，请您重点关注：</div>
        <br>
        <div>1．VLLSHOP主要为企业客户提供服务，故本政策仅适用于VLLSHOP直接处理企业客户的员工以及授权操作人员的个人信息。此外，如果我们的企业客户在他们的产品中嵌入了我们的服务（如我们的建站服务等），受企业客户委托，在其向终端用户提供产品过程中需要处理该终端用户个人信息的，我们仅作为个人信息处理者，按照与企业客户达成的协议，处理其终端用户的个人信息。企业客户作为个人信息控制者向其终端用户承担所有责任，并负有义务向终端用户披露如何处理、保护终端用户的个人信息。您应向您的终端用户展示隐私政策的具体内容，并向其提供咨询和权利请求的响应。</div>
        <br>
        <div>2. 所有重点内容，我们采用“<span class="bold">加粗</span>”的书写方式进行提示。对个人敏感信息，我们用“<span class="italicBold">加粗斜体</span>”的书写方式进行特别提醒，希望您在阅读时特别关注。在您向我们提供您的任何<span class="italicBold">个人敏感信息</span>前，您已慎重考虑并在此确认该等提供是适当的，您在此同意您的<span class="italicBold">个人敏感信息</span>可按照本政策所述的目的和方式进行处理。</div>
        <br>
        <div><span class="bold">3．VLLSHOP仅面向年满十八（含）周岁以上的用户提供服务。</span><span class="underline">针对18周岁以下未成年人，我们不采集也不会向其提供任何本平台产品和/或服务。在使用我们的产品和/或服务前，请确认使用者已经符合年龄要求，并仔细阅读、了解了本政策中的详细信息。</span>我们不会在明知的情况下收集未满十八周岁未成年人的个人信息。如果您发现我们无意收集了未成年人的个人信息，请您立即通知我们，我们会尽快设法删除相关数据。</div>
        <br>
        <div>4. 我们为您提供的个人信息权利实现方式包括但不限于访问或更正、删除您的个人信息、注销账号、撤回同意和及时得到响应等，以确保您实现对您个人信息的控制权。</div>
        <br>
        <div class="bold">5. 在使用本平台前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用本平台相关产品与/或服务。我们建议您使用本平台产品和/或服务时，对您提供的相关个人信息主体的信息已经得到合法授权并做出了明智的决策。如果您不同意本政策任何内容，您应立即停止使用VLLSHOP。</div>
        <br>
        <div>6．为了便于相关个人信息主体阅读及理解，我们用尽可能简单易懂的语句进行表述，并对下述关键术语进行了定义，请参见本政策“附录：<span class="bold"> 关键词定义</span>”。</div>
        <br>
        <br>
        <div class="subTitle">本政策将帮助您了解以下内容：</div>
        <br>
        <div class="bold">一、我们如何收集和使用个人信息主体的个人信息</div>
        <div class="bold">二、我们如何使用Cookie及其他同类追踪技术</div>
        <div class="bold">三、我们如何委托处理、共享、转让、公开披露个人信息主体的信息</div>
        <div class="bold">四、如何使您拥有管理个人信息的权力</div>
        <div class="bold">五、如何保存和保护个人信息</div>
        <div class="bold">六、如何保护未成年人的个人信息</div>
        <div class="bold">七、如何更新本政策</div>
        <div class="bold">八、管辖与法律适用</div>
        <div class="bold">九、如何联系我们</div>
        <div class="bold">十、关键词定义</div>
        <br>
        <h4>一、如何收集和使用个人信息主体的个人信息</h4>
        <div>我们根据合法、正当、必要的原则，为实现本政策下述的各项功能，收集和使用您在使用本平台过程中主动提供的、因您使用本平台而产生的以及我们从第三方获取的相关个人信息主体的个人信息。无论通过上述何种方式收集和使用相关个人信息主体的个人信息，<span class="bold">我们只会在征得您授权的情况下进行。请您确保您已获得您终端用户的同意，除非在某些情况下，基于法律义务或者可能需要保护您或其他人的重要利益向相关个人信息主体收集个人信息。</span></div>
        <br>
        <div>请您理解，由于商业策略的变化，本平台产品和/或服务的功能也可能进行更迭和发展。如果我们要将相关个人信息主体的个人信息用于本政策未载明的其它用途，或基于其他特定目的而收集、使用相关个人信息主体的个人信息，我们将以合理的方式告知您，并在收集、使用前再次征得您的同意。</div>
        <br>
        <div class="bold">（一）向您提供产品与/或服务过程中，您主动提供的个人信息</div>
        <div class="bold">1.注册/登录账号</div>
        <div>为使用平台您首先需要注册一个VLLSHOP账号以成为我们的用户。基于此，您需要向我们提供注册账号所需的电子邮箱、中国大陆地区的手机号、登录密码和我们发送给您的验证码来验证您的身份是否有效。</div>
        <div>完成注册后，您可以选择通过手机号码+验证码或电子邮箱+登录密码的方式进行登录，后以注册用户身份浏览和使用VLLSHOP。</div>
        <br>
        <div class="bold">2.创建试用店铺</div>
        <div>在创建试用店铺过程中，如果您还希望其他运营人员使用邮箱登录店铺进行店铺管理的，需要提供店铺运营人员的电子邮箱。请您确保在提供其他人员的个人信息时，已获得其本人同意。</div>
        <br>
        <div class="bold">3.客户管理</div>
        <div>创建店铺后，您可以导入已注册/下单客户信息，以便进行店铺运营和客户管理。请您确保在您所导入的其他人员个人信息，在收集时已获得以该方式使用其个人信息的同意。</div>
        <br>
        <div class="bold">4.付费功能</div>
        <div>当您使用VLLSHOP的付费功能时，我们会收集您的<span class="italicBold">付费记录</span>，以便您在平台中可以查询已发生的交易记录，同时尽最大程度保护您的财产、虚拟财产安全。上述信息属于<span class="italicBold">敏感个人信息</span>，请您不要随意向第三方提供。收集上述信息为实现相关功能所必须，否则将无法完成交易。</div>
        <br>
        <div class="bold">5.上传/发布内容</div>
        <div>我们会收集您通过本平台自行上传或发布的内容信息，包括文字、共享照片、录音、录像及该类信息的日期、时间和地点。当您使用上述功能时，我们会向您请求授权使用您终端设备中的摄像头、相册、麦克风和位置权限。您可以自行选择是否向我们提供该等授权，如果拒绝，将无法使用相关功能，但不影响您使用VLLSHOP的其他功能。</div>
        <br>
        <div class="bold">6.体检中心</div>
        <div>为了帮助您更好地改进店铺，实现客户增长，我们会提供体检服务，以向您提供如何改进相应店铺的建议。在该功能下，我们可能会使用您提供的店铺页面内的图片，请您确保相关图片并未包含您或用户的个人信息。如包含相关个人信息，请您确保已获得个人信息主体的同意。如您无法确保图片中是否含有个人信息或者无法获取授权的，请您不要使用此项功能，但不影响您使用VLLSHOP的其他功能</div>
        <br>
        <div class="bold">7.商品推荐</div>
        <div>当您需要在商品详情页推荐商品时，为了达到更为精准的推荐效果，我们会收集您的终端用户<span class="italicBold">浏览数据和行为数据</span>。请您在选择该服务时，确保已经向终端用户告知相关信息收集及使用情况并获取其同意。如果您无法确保您的最终用户已经同意被采集其上述信息，请您不要使用此项功能，但不影响您使用VLLSHOP的其他功能。</div>
        <br>
        <div class="bold">8.添加色板</div>
        <div>当您使用添加色板功能（包括色板管理、色板创建/编辑、自定义商品色板、色斑外观配置）时，我们会收集使用您的店铺数据（包括卖家ID和店铺ID），以将您所添加色板的操作对应到您的店铺。如果拒绝提供此类信息，请您不要使用此项功能，但不影响您使用VLLSHOP的其他功能。</div>
        <br>
        <div class="bold">9.添加购物车</div>
        <div>当您的最终用户使用“添加购物车”功能时，为了使其操作对应到您的店铺，我们会收集并使用您的店铺ID，以此来完成您与您最终用户的交易。如您拒绝提供此类信息，请您不要使用此项功能。我们提示您，您建站的效果会由此受到影响，但不影响您使用VLLSHOP的其他功能。</div>
        <br>
        <div class="bold">10.客服</div>
        <div>为了改进服务质量，在与我们客服团队联系时，我们可能会要求您提供电话、微信账号和姓氏信息，以便为您进行客服交流和服务</div>
        <br>
        <div class="bold">（二）向您提供平台服务过程中我们主动收集的个人信息</div>
        <div>在符合法律法规的前提下，为了更好地为您提供服务以及保障账号的安全，以及为避免出现滥用VLLSHOP账号的行为，当您在VLLSHOP进行浏览和搜索时，我们会收集和使用您在使用我们的产品与/或服务过程中产生的设备信息和日志信息，具体如下：</div>
        <div><span class="bold">设备信息：</span>我们会收集您的个人常用设备信息，包括IP地址、设备型号、设备识别码、操作系统、分辨率、电信运营商。</div>
        <div><span class="bold">日志信息：</span>我们会自动收集您使用本平台的详细信息并作为网络日志进行保存，包括网络诊断、卡顿信息、点击事件、关卡成功或失败情况、<span class="italicBold">浏览记录</span>、点击记录和潜在风险的URL，并根据相关法律要求进行保存。</div>
        <br>
        <div class="bold">（三）终端用户个人信息的授权与同意</div>
        <div>您十分清楚，为了使用平台之目的，您需要我们收集、存储、加工来自于您产品终端用户的个人信息。因此，您必须承诺：</div>
        <div>1）您已经获得终端用户充分必要的授权、同意和许可，允许使用我们履行服务所需的目的直接收集和使用其个人信息；</div>
        <div>2）您已经获得终端用户充分必要的授权、同意和许可，允许我们和合作方对已收集的终端用户个人信息进行去标识化的分析与聚合性的处理；</div>
        <div>3）您已经遵守并将持续遵守适用的法律、法规和监管要求，包括但不限于制定和公布有关个人信息保护和隐私保护的相关政策；</div>
        <div>4）您已向终端用户提供易于操作的选择机制，告知终端用户如何以及何时可以行使选择权，并说明行使选择权后如何以及何时可以修改或撤回该选择，使得用户可以选择同意或不同意为商业目的而收集和使用其个人信息。</div>
        <div>5）您已经向终端用户告知如何向您行使终端用户权利（如何访问、更正其个人信息、行使删除权、更改其授权同意的范围等操作）。您的终端用户不会因为我们按照本政策处理其个人信息而对我们产生任何形式的诉求、诉请、投诉等。如向我们提出，您将负责为终端用户进行解决。</div>
        <br>
        <br>
        <div class="bold">如因您违反上述承诺导致您的终端用户对我们主张任何形式的索赔或权利要求，或导致我们被卷入到法律或行政程序，您将负责全面解决，并赔偿我们因此可能遭受的任何形式的损失。</div>
        <br>
        <br>
        <div class="bold">（四）征得授权同意的例外</div>
        <div>根据法律法规，以下情形中收集和使用的个人信息无需征得个人信息主体的同意：</div>
        <div>1.与履行法律法规规定的义务相关的；</div>
        <div>2.与国家安全、国防安全直接相关的；</div>
        <div>3.与公共安全、公共卫生、重大公共利益直接相关的；</div>
        <div>4.与刑事侦查、起诉、审判和判决执行等直接相关的；</div>
        <div>5.出于维护个人信息主体或其他个人的生命、财产等重大合法权益相关但又很难得到本人授权同意的；</div>
        <div>6.所涉及的个人信息是个人信息主体自行向社会公众公开的；</div>
        <div>7.根据个人信息主体要求签订和履行合同所必需的；</div>
        <div>8.从合法公开披露的信息中收集个人信息的，如通过合法的新闻报道、政府信息公开等渠道；</div>
        <div>9.维护提供我们平台的安全稳定运行所必需的，例如发现、处置我们平台产品与/或服务的故障；</div>
        <div>10.法律法规规定的其他情形。</div>
        <br>
        <br>
        <h4>二、如何使用Cookie及其他同类追踪技术</h4>
        <div class="bold">（一）什么是Cookie</div>
        <div>Cookie是个人信息主体登录网站或浏览网络内容时，网站服务器存放在个人信息主体的电脑、移动电话或其他智能终端设备内的小型文件，通常包含标识符、站点名称以及一些号码和字符。当个人信息主体再次访问相应网站时，网站就可通过 Cookie 识别个人信息主体的浏览器。Cookie可能会存储用户偏好及其他信息。</div>
        <br>
        <div class="bold">（二）如何使用Cookie</div>
        <div>当个人信息主体使用我们网站时，我们可能通过Cookies或同类技术收集个人信息主体的设备型号、操作系统、设备标识符、登录IP地址信息，以及缓存个人信息主体的<span class="italicBold">浏览信息</span>、点击信息，以查看个人信息主体的网络环境。通过Cookies，我们可以在个人信息主体访问网站时识别个人信息主体的身份，并不断优化网站的用户友好程度，并根据个人信息主体的需要对网站做出调整。个人信息主体也可以更改浏览器的设置，使浏览器不接受我们网站的Cookies，但这样可能会影响个人信息主体对网站的部分功能的使用。</div>
        <div>在VLLSHOP网站，借助于Cookie和其他类似技术，我们可以在个人信息主体每次使用我们产品与/或服务时识别个人信息主体是否属于我们的用户或者终端用户，而不必在每个页面上重新登录和验证。</div>
        <br>
        <div class="bold">（三）如何管理Cookie</div>
        <div>个人信息主体可根据自己的偏好管理或删除某些类别的追踪技术。很多网络浏览器均设有“禁止追踪”（Do Not Track）功能，该功能可向网站发布“禁止追踪”的请求。</div>
        <div>除了我们提供的控件之外，个人信息主体还可以选择在Internet浏览器中启用或禁用Cookie。大多数互联网浏览器还允许个人信息主体选择是要禁用所有Cookie还是仅禁用第三方Cookie。默认情况下，大多数互联网浏览器都接受Cookie，但这可以更改。有关详细信息，请参阅Internet浏览器中的帮助菜单或设备随附的文档。</div>
        <div>以下链接提供了有关如何在所有主流浏览器中控制Cookie的说明：</div>
        <div>-Google Chrome：<span class="underline">https://support.google.com/chrome/answer/95647?hl=en</span></div>
        <div>-IE：<span class="underline">https://support.microsoft.com/en-us/help/260971/description-of-cookies</span></div>
        <div>-Safari（桌面版）：<span class="underline">https://support.apple.com/kb/PH5042?locale=en_US</span></div>
        <div>-Safari（移动版）：<span class="underline">https://support.apple.com/en-us/HT201265</span></div>
        <div>-火狐浏览器：<span class="underline">https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies</span></div>
        <div>-Opera：<span class="underline">https://www.opera.com/zh-cn/help</span></div>
        <div>如个人信息主体使用其他浏览器，请参阅浏览器制造商提供的文档。</div>
        <div>在我们网站，个人信息主体可以通过清除缓存的方式删除已有的追踪技术。</div>
        <div>当个人信息主体在非登录状态下浏览页面时，为向个人信息主体提供相应服务，我们会收集实现浏览功能所必需收集 Cookie 信息，但会在一个月内删除这些Cookie信息。</div>
        <div>请个人信息主体知悉，倘若个人信息主体拒绝使用或清除已有的追踪技术，则需要在每一次访问时亲自更改用户设置，并且，我们可能无法向个人信息主体提供更加优质的使用体验，部分服务亦可能会无法正常运作。</div>
        <br>
        <h4>三、我们如何委托处理、共享、转让、公开披露个人信息主体的信息</h4>
        <div class="bold">（一）委托处理</div>
        <div>为了向您提供更完善、更优质的产品和服务，某些功能可能由我们的技术服务合作方提供，我们可能会委托合作方 （包括技术服务提供方）处理个人信息主体的某些个人信息。<span class="bold">（例如您在使用VLLSHOP付费产品或自动扣款功能时，我们可能会委托第三方支付公司处理您的某些个人信息。）</span>对我们委托处理个人信息的公司、组织和个人，我们会与其签署严格的保密协定，明确数据处理事项、处理期限、处理性质、处理目的以及双方责任等，要求合作方仅按照我们的要求、本政策的规定以及其他任何相关的保密和安全措施来处理个人信息。如果您拒绝我们的供应商在提供服务时收集为提供服务所必需的个人信息，将可能导致您或您的终端用户在使用我们产品与/或服务的过程中无法享受该供应商提供的服务。例如我们会聘请服务提供商来协助我们完成实名认证。</div>
        <br>
        <div class="bold">（二）共享</div>
        <div>除非存在以下一种或多种情形，否则我们不会与任何第三方公司、组织或个人共享个人信息：</div>
        <div>1.基于个人信息主体自行提出的要求、或事先获得个人信息主体的明示授权或同意；</div>
        <div>2.与我们履行法律法规规定的义务相关的；</div>
        <div>3.与国家安全、国防安全直接相关的；</div>
        <div>4.与公共安全、公共卫生、重大公共利益直接相关的</div>
        <div>5.与刑事侦查、起诉、审判和判决执行等直接相关的</div>
        <div>6.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</div>
        <div>7.个人信息主体自行向社会公众公开的您的个人信息；</div>
        <div>8.从合法公开披露的信息中收集个人信息的，如通过合法的新闻报道、政府信息公开等渠道。</div>
        <div>9.与我们关联公司及合作伙伴进行必要信息的共享：我们仅会出于合法、正当、必要、特定、明确的目的共享个人信息，并且只会共享为您或您的终端用户提供服务所必要的个人信息。我们不会共享可以识别个人信息主体身份的个人信息，除非法律法规另有规定。与委托处理下的合作方不同，该等共享的合作方为数据控制者，其将以自己的名义获得个人信息主体的同意后处理个人信息。合作方可能有其独立的隐私政策和用户服务协议，我们建议您阅读并遵守共享合作方的用户协议及隐私政策。以下为我们与第三方共享信息的详细情况：</div>
        <div>A.与关联公司间共享：为便于我们基于SHOPLINE账号向您提供平台产品和/或服务，推荐您可能感兴趣的信息，识别用户账号异常，保护SHOPLINE关联公司或其他用户或公众的人身财产安全免遭侵害，经过您和您终端用户的同意，您和您终端用户的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息，且受本政策中所声明目的的约束，如果我们共享了<span class="italicBold">个人敏感信息</span>或关联公司改变个人信息的使用及处理目的，将再次征求您和您的终端用户的授权同意。</div>
        <div>B. 与授权合作伙伴共享：</div>
        <div>（1）广告类授权合作伙伴。除非得到您的授权，否则我们不会将您终端用户的个人信息向提供广告服务的合作伙伴共享。我们会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，但不会提供您终端用户的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别到终端用户个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以进行广告或决策建议。</div>
        <div>（2）统计分析服务类的授权合作伙伴。除非得到您的授权，否则我们不会将您终端用户的个人信息与提供统计分析服务的合作伙伴共享。我们会向这些合作伙伴提供汇总后的信息，以便它不会识别到终端用户个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以进行统计分析。</div>
        <div>（3）支付合作类的授权合作伙伴。在您为使用VLLSHOP的某些付费或订阅功能而使用国际信用卡支付美金时，收单服务将通过与VLLSHOP 具有合作关系的的第三方支付公司Checkout进行提供。我们会向这些合作伙伴提供为实现收单功能所提供的必要信息，不会提供额外个人信息。
          在您使用国际信用卡支付美金时，您支付的费用将由Checkout收取后支付给VLLSHOP,并通过有资质的第三方支付公司，最终支付给深圳市万鲸科技有限公司。
        </div>
        <div>（4）其他供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的其他供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、提供客户服务、支付便利或进行学术研究和调查。我们会与其约定严格的数据保护措施，令其按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。</div>
        <div>提示您注意，本平台可能包含第三方提供的功能或第三方提供的网站和服务的链接。本政策所描述的规定与程序不适用于第三方网站。您向第三方网站或服务提供的任何信息都将直接提供给这些服务的网络运营商，即使通过本平台进行访问，也须遵守该运营商适用的隐私政策和用户协议（如有）。对于第三方网站或服务的内容、个人信息和安全措施和政策，我们概不负责。我们建议您在向第三方提供信息之前先了解其隐私政策和用户协议。</div>
        <br>
        <div class="bold">（三）转让</div>
        <div>原则上我们不会将个人信息控制权向其他公司、组织或个人进行转让，但以下情况除外：</div>
        <div>1. 基于个人信息主体自行提出的要求、或事先获得您的明示授权或同意；</div>
        <div>2．与我们履行法律法规规定的义务相关的；</div>
        <div>3. 与国家安全、国防安全直接相关的；</div>
        <div>4. 与公共安全、公共卫生、重大公共利益直接相关的；</div>
        <div>5. 与刑事侦查、起诉、审判和判决执行等直接相关的；</div>
        <div>6. 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</div>
        <div>7. 个人信息主体自行向社会公众公开的您个人信息；</div>
        <div>8. 从合法公开披露的信息中收集个人信息的，如通过合法的新闻报道、政府信息公开等渠道；</div>
        <div>9. 发生公司收购、兼并、重组、破产，运营主体变更时的转让。</div>
        <div class="bold">请您知悉：如具备上述事由确需转让，我们将在转让前向您告知转让信息的目的、类型、信息受让方（如涉及敏感信息，我们还将告知涉及的敏感信息的内容），并在征得您同意后再转让，但法律法规另有规定的除外。变更后的运营主体将继续履行个人信息控制者的责任和义务；如公司发生破产或者停运且无承接方的，我们将对您的个人信息做删除或者匿名化处理。</div>
        <br>
        <div class="bold">（四）公开披露</div>
        <div>原则上我们不会向社会或不特定人群发布您的个人信息，但基于与您的约定或根据法律法规应当披露的除外。</div>
        <br>
        <h4>四、如何使您拥有管理个人信息的权利</h4>
        <div class="bold">（一）访问和更正个人信息</div>
        <div>您有权随时登录并访问您VLLSHOP账号，查看您的个人信息。如您发现我们收集、存储、使用、披露的您的个人信息有错误或不完整的，或者在法律法规规定的其他情况下，您可以通过登录https://www.VLLSHOP.com ，并进入“个人中心”后进行更正或补充完善您的个人信息。</div>
        <div>如您希望访问或更正您的其他个人信息，或在行使上述权利过程中遇到困难，您可以通过本政策第九条所述的方式联系我们，我们将在核实您的身份后及时响应您的请求，但法律法规另有规定的或本政策另有约定的除外。</div>
        <br>
        <div class="bold">（二）删除个人信息</div>
        <div>在以下情形中，您可以通过本政策第九条所述的方式联系客服，向我们提出删除您个人信息的请求，但已做数据匿名化处理或法律法规另有规定的除外：</div>
        <div>1.我们违反法律、行政法规的规定或与您的约定，收集、使用个人信息的；</div>
        <div>2.我们违反法律法规规定或违反与您的约定向第三方共享、转让个人信息，我们将立即停止共享、转让的行为，并通知第三方及时删除；</div>
        <div>3.您通过注销等方式终止使用本平台产品与/或服务的；</div>
        <div>4.法律法规等规定的其他情形。</div>
        <div class="bold">在删除相关信息前，我们可能会要求您进行身份验证，以保障账户安全。当我们决定响应您的删除请求时，我们将尽快从VLLSHOP产品与/或服务器中删除您的信息。但出于监管机关打击犯罪、维护国家安全的要求，我们会在法律允许的期限内对您的通讯信息和交易记录进行保存，但我们不会进行任何使用行为。请您知悉，我们可能不会将您需要删除的信息立即从备份系统中删除，但会在备份系统更新时删除。</div>
        <br>
        <div class="bold">（三）注销账号</div>
        <div>除非法律法规另有规定或本政策另有约定，您可以通过本政策第九条所述的方式联系客服来注销您的VLLSHOP账号。 请您理解并知悉，注销您的本平台账号将导致您永久失去对账户和账户中数据的访问权。我们将在验证您的身份并与您协商处理完您账户中的资产后，为您提供账户注销服务。为了向您提供更加便捷的注销方式，我们后续会不断优化我们的产品，并且通过页面公告向您告知。在注销账号后，除非根据法律规规定需要留存个人信息的，我们将及时删除您的个人信息或对其作匿名化处理。</div>
        <div>您注销账号的行为是不可逆的，一旦您的账号被注销，我们不再收集您的个人信息，也将不再为您提供我们的产品和/或服务，因此我们建议您谨慎选择注销账号。</div>
        <br>
        <div class="bold">（四）约束信息系统自动决策</div>
        <div>在本平台某些业务功能中，我们可能依据信息系统、算法等在内的非人工自动决策机制作出决定。如果这些决定显著影响您的合法权益，您有权要求我们作出解释，您可以通过本政策第九条提供的方式联系我们。</div>
        <br>
        <div class="bold">（五）响应请求</div>
        <div>为实现本条所述的用户权利，或您存在任何疑问、投诉、意见或建议的，您可以通过本政策第九条提供的联系方式与我们联系。为确保安全，我们可能会先要求验证您用户的身份，然后再处理您的请求。对于您的请求，原则上将在收到您请求后的15 个工作日内验证您的用户身份并做出答复。</div>
        <div>对于您合理的请求，我们原则上不收取费用。对于一定时期内多次重复的请求，我们将视情况收取一定成本费用；对于需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、或存在其他显著困难的请求，我们将为您提供替代方法。</div>
        <div>存在以下情形时，我们将拒绝您提出的请求：</div>
        <div>1.履行法律法规规定的义务相关的；</div>
        <div>2.与国家安全、国防安全直接相关的；</div>
        <div>3.与公共安全、公共卫生、重大公共利益直接相关的；</div>
        <div>4.与刑事侦查、起诉、审判和执行判决等直接相关的；</div>
        <div>5.有充分证据表明用户存在主观恶意或滥用权利的；</div>
        <div>6.出于维护用户或其他个人的生命、财产等重大合法权益但又很难 得到本人授权同意的；</div>
        <div>7.响应用户的请求将导致用户或其他个人、组织的合法权益受到严重损害的；</div>
        <div>8.涉及商业秘密的。</div>
        <div>请知悉，针对使用本平台产品和/或服务的终端用户（而不是本平台的直接客户）提出的请求，我们建议您直接响应。除非我们接到您的请求予以协助，否则我们会将收到的您的终端用户的请求转发您或者请终端用户直接找您寻求帮助。</div>
        <br>
        <h4>五、我们如何保存和保护个人信息</h4>
        <div class="bold">（一）个人信息的保存</div>
        <div class="bold">１．保存期限</div>
        <div>您在使用VLLSHOP产品和／或服务期间，我们将持续为您保存您和您终端用户的个人信息。我们承诺，除非法律法规另有要求，我们对您和您终端用户个人信息的存储时间限于实现您和您终端用户授权使用目的所必需的最短时间，法律法规另有规定或者用户另行授权同意的除外。
          如果您注销账号、主动删除上述信息、我们因经营不善或其他原因出现停止运营的情况，我们将依据法律法规规定停止对您个人信息进行收集，同时删除已收集到的个人信息或对其做匿名化处理。
        </div>
        <div class="bold">２．保存地域</div>
        <div>原则上我们会将在中国境内运营所收集和产生的个人信息存储在中国境内，不会进行跨境传输。请您知悉，为进行统计和分析目的，我们可能会将您的IP地址、浏览记录传输至新加坡。在此情况下，我们会确保您的个人信息得到在中国境内足够同等的保护，并且在进行跨境数据传输前实施去标识化等措施。</div>
        <br>
        <div class="bold">（二）个人信息的保护</div>
        <div>我们十分重视您的个人信息安全，为此我们采用了符合行业标准的安全技术措施及配套的组织架构和管理体系等多层面保护措施，以最大程度防止您的个人信息被泄露、毁损、误用、未授权访问、未授权披露或更改，具体包括：</div>
        <div class="bold">１．数据安全技术措施</div>
        <div>为保障信息安全，我们努力采取各种合理的安全技术措施来保护个人信息，使您和您终端用户的个人信息不会被泄漏、毁损或者丢失。我们采用了如SSL等加密传输技术，保护数据在其传输过程中的安全，并使用妥善的保护机制以防止数据遭到恶意攻击。我们对个人信息采取加密存储和数据权限控制机制，防止您和您终端用户的个人信息遭遇未授权的访问、公开披露、使用、修改、人为或意外的损坏或丢失等。</div>
        <div class="bold">２．数据安全组织和管理措施</div>
        <div>根据有关法规要求，我们任命了个人信息保护负责人和个人信息保护工作机构。我们还建立了相关的内控管理流程，以最小授权为原则，对可能接触到您和您终端用户个人信息的工作人员，严格设定信息访问权限，控制个人信息的知悉范围。</div>
        <div>我们建立了数据安全使用的内部规范制，对可能接触到您和您终端用户的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。</div>
        <div>我们组织员工参加安全与隐私保护相关培训并要求其完成规定的考核，加强员工对于保护个人信息重要性的认知。</div>
        <div class="bold">３. 合作协议条款保证</div>
        <div>在我们从第三方间接收集您的个人信息前，我们会明确以书面形式（如合作协议、承诺书）要求该第三方在已经取得您明示同意后收集以及处理您和您终端用户的个人信息，并要求第三方对个人信息来源的合法性和合规性以书面协议的形式作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应的法律责任。</div>
        <div>在我们向合作伙伴共享您和/或您终端用户的个人信息前，我们会严格要求合作伙伴承担信息保护义务与责任。为此，我们会在合作之前要求生态伙伴签署<span class="bold">数据处理协议或在双方签署的合作协议中列明有关数据保护的条款</span>。协议严格约定合作伙伴对用户信息的保密义务，包括信息的保管、使用和流转等均应满足我们的管控要求，接受我们的审核、监督和审计，一旦有任何违反，我们会要求对方承担相应的法律责任。</div>
        <div class="bold">4. 安全事件处理</div>
        <div>如不幸发生了个人信息安全事件，我们将立即启动应急预案，采取补救措施，记录事件内容，按照适用法律法规的有关规定及时上报。若可能会给您和/或您终端用户的合法权益造成严重损害的，如造成个人敏感信息的泄露，我们将向您告知该安全事件的基本情况和其可能的影响、我们已采取或将要采取的处置措施、您可选择的防范和降低风险的建议、我们针对您和/或您终端用户提供的补救措施以及个人信息保护负责人和个人信息保护工作机构的联系方式等。我们将及时将以上事件相关情况以邮件、信函、电话或推送通知等方式告知您，难以逐一告知时，我们会采取合理、有效的方式发布警示公告。</div>
        <div>请您知悉并理解，互联网并非绝对安全的环境，我们强烈建议您通过安全方式、使用复杂密码，协助我们保证您的账号安全。如您发现自己的个人信息泄密，尤其是您的账号或密码发生泄露，请您立即根据本政策第九条提供的联系方式联系我们，以便我们采取相应措施来保护您的信息安全。</div>
        <br>
        <h4>六、如何保护未成年人的个人信息</h4>
        <div>本平台仅面向十八周岁（含）以上用户提供产品和/或服务。我们不会在知情的情况下收集未成年人的个人信息。如果我们不小心收集到了未成年人的信息，我们在知情后会尽快删除。如果您认为我们可能不当地持有关于未成年人的信息，请依照本政策第九条所述方式联系我们。</div>
        <h4>七、如何更新本政策</h4>
        <div>我们可能适时修订本政策的条款，该修订构成本政策的一部分。如该等修订造成您在本政策下权利的实质减少，我们将在修订生效前通过在主页上显着位置提示或向您发送电子邮件或以其他方式通知您。在该种情况下，如果您不同意本政策，或对本政策修改、更新的内容有异议，您可以选择停止使用我们的产品与/或服务或者注销您的账号。但请您知悉，您账号注销之前、停止使用本服务之前的行为和活动仍受本政策的约束。</div>
        <br>
        <div class="bold">若涉及重大、实质性变更，我们会依据具体情况，以显著的方式通知您。</div>
        <div class="bold">重大、实质性变更的情形包括但不限于以下情形：</div>
        <div class="bold">1．我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</div>
        <div class="bold">2．您参与个人信息处理方面的权利及其行使方式发生重大变化；</div>
        <div class="bold">3．我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</div>
        <div class="bold">4．个人信息共享、转让或公开披露的主要对象发生变化；</div>
        <div class="bold">5．我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</div>
        <div class="bold">6．个人信息安全影响评估报告表明存在高风险时。</div>
        <br>
        <h4>八、管辖与法律适用</h4>
        <div class="bold">本隐私政策的成立、生效、履行、解释及纠纷解决，适用中华人民共和国法律，并明确排除其冲突法规范的适用。</div>
        <div class="bold">若您和我们发生任何纠纷或争议，首先应友好协商解决。协商不成时，任何一方均可向深圳市福田区人民法院提起诉讼。该法院享有排他性管辖权。</div>
        <br>
        <h4>九、如何联系我们</h4>
        <div>如对本政策内容有任何疑问、意见或建议，您可以通过以下方式与我们联系：</div>
        <div>1）VLLSHOP的客户服务热线：400－829－9969；</div>
        <div>2）个人信息保护专职部门邮箱：legal@vllshop.com</div>
        <div>3）或将信件寄至：常用办公地址：深圳市南山区航空航天大厦1号楼7003收。</div>
        <br>
        <h4>十、关键词定义</h4>
        <div>您：指注册并使用VLLSHOP的卖家用户和/或其员工和/或授权操作本平台的其他人员。
          个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本政策中涉及的个人信息包括姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信通讯联系方式、通信记录和内容、密码、财产信息、交易信息等。
        </div>
        <div class="italicBold">个人敏感信息：指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，包括身份证件号码、个人生物识别信息、银行账户、财产信息、征信信息、行踪轨迹、健康生理信息、交易信息。</div>
        <div>去标识化：指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别或者关联您的过程。</div>
        <div>删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</div>
        <div>匿名化：指通过对个人信息的技术处理，使得您无法被识别或者关联，且处理后的信息不能被复原的过程。</div>
        <div>中国大陆或中国大陆境内：指中华人民共和国大陆地区（仅为本政策之目的，不包含香港特别行政区、澳门特别行政区和台湾地区）。</div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style scoped>

</style>
